<template>
<div id="page-articulos">
    <div>
        <v-breadcrumbs :items="breadcrumbs">
            <v-icon slot="divider">forward</v-icon>
        </v-breadcrumbs>
    </div>
    <v-container fluid fill-height>
        <v-row align-center justify-center>
            <v-col>
                <base-material-card color="primary" icon="library_books" :title="'Conteo Inventario: '+(model.descripcion!=undefined?model.descripcion:'')" class="elevation-1 px-5 py-3">
                    <v-card-actions>
                        <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-row>
                        <v-col lg="3" md="3" sm="3">
                            <v-text-field label="Ingrese Código de Artículo" v-model="codigo_articulo"
                                @keyup.enter="codigo_articulo = codigo_articulo.toString().toUpperCase(); buscarArticulo(0,'','aumentar', 'inicio')"
                                @input="upperCaseInput('codigo_articulo')" ref="ingresaarticulo">
                            </v-text-field>
                        </v-col>
                        <v-col lg="2" md="2" sm="2">
                            <v-btn small color="info" @click="buscarArticulo(0,'','aumentar', 'inicio')">
                                <v-icon class="white--text">search</v-icon> Buscar Código
                            </v-btn>
                        </v-col>
                        <v-col lg="4" md="4" sm="4">
                            <v-autocomplete v-model="id_articulo" :items="articulos" :hide-no-data="true" :loading="loadingArticulos" 
                                placeholder="Ingrese nombre de artículo"
                                @change="buscarArticulo(0, id_articulo, 'aumentar', 'inicio')"
                                clearable item-text="nombre" item-value="codigo_barras" label="Nomde de Artículo">
                                </v-autocomplete>                            
                        </v-col>
                        <v-col lg="2" md="2" sm="2">
                            <v-btn small color="info" @click="buscarArticulo(0, id_articulo, 'aumentar', 'inicio')" 
                                :disabled="(id_articulo == null || id_articulo == '' || id_articulo == undefined)"
                            >
                                <v-icon class="white--text">add</v-icon> Agregar
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-card-text>
                        <div id="datagrid">

                            <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                                <template slot="noResults">
                                    <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                                </template>
                                <template slot="codigo_articulo" slot-scope="props">
                                    {{ props.row.codigo_articulo.join(", ") }}
                                </template>
                                <template slot="existencia" slot-scope="props">
                                    <div style="text-align:center">{{props.row.existencia}}</div>
                                </template>
                                <template slot="fecha" slot-scope="props">
                                    {{parseDateTime(props.row.fecha)}}
                                </template>
                                <template slot="cantidad" slot-scope="props">
                                    <div style="font-weight:bold;text-align:center">{{props.row.cantidad}}</div>
                                </template>

                                <template slot="actions" slot-scope="props">
                                    <v-btn x-small fab dark color="red" @click.native="delete_articulo(props.row)" title="Quitar Artículo" v-tippy>
                                        <v-icon>delete</v-icon>
                                    </v-btn>&nbsp;
                                    <v-btn x-small fab dark color="blue" @click.native="mostrarEditar(props.row,'editar')" title="Editar Conteo" v-tippy>
                                        <v-icon>edit</v-icon>
                                    </v-btn>&nbsp;
                                    <v-btn x-small fab dark color="success" @click.native="mostrarEditar(props.row,'aumentar')" title="Aumentar" v-tippy>
                                        <v-icon>add</v-icon>
                                    </v-btn>&nbsp;
                                    <v-btn x-small fab dark color="info" @click.native="mostrarEditar(props.row,'disminuir')" title="Disminuir" v-tippy>
                                        <v-icon>mdi-minus</v-icon>
                                    </v-btn>

                                </template>
                            </v-client-table>

                        </div>
                    </v-card-text>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>

    <v-dialog v-model="modalEditar" max-width="550px">
        <v-card>
            <v-card-title>
                <v-card class="primary white--text titulomodal">
                    <v-icon class="white--text">{{opcion_modal=="editar"?'edit':(opcion_modal=="aumentar"?'add':'mdi-minus')}}</v-icon>&nbsp;
                    <span class="subheading">
                        <strong>{{opcion_modal.toString().toUpperCase()}} CANTIDAD</strong><br>
                        <div style="text-align:center !important;"><strong>{{nombre_articulo_editar}}</strong></div>
                    </span>
                </v-card>
                <v-spacer></v-spacer>
                <v-btn icon @click.native="modalEditar = false" class="close_modal">
                    <v-icon class="white--text">cancel</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" lazy-validation>
                    <v-container>
                        <br>
                        <v-row justify="center">
                            <v-col sm="3" md="3" lg="3" style="text-align:right !important;">
                                <v-btn small color="grey" @click.native="(cantidad_editable!=null && cantidad_editable>0)?cantidad_editable=parseFloat(cantidad_editable)-1:cantidad_editable=0">
                                    <v-icon>remove</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col sm="3" md="3" lg="3" style="text-align:center !important;">
                                <v-text-field :label="opcion_modal=='editar'?'Cantidad':(opcion_modal=='aumentar'?'Aumentar':'Disminuir')" v-model="cantidad_editable"
                                v-on:keydown.enter.prevent='1' @keyup.enter="editar(opcion_modal)"
                                ref="ingresacantidad" :rules="[validaCantidadFloat]"></v-text-field>
                            </v-col>
                            <v-col sm="3" md="3" lg="3" style="text-align:left !important;">
                                <v-btn small color="grey" @click.native="(cantidad_editable!=null && cantidad_editable>=0)?cantidad_editable=parseFloat(cantidad_editable)+1:cantidad_editable=0">
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-btn @click.native="modalEditar = false">
                                <v-icon>cancel</v-icon> Cancelar
                            </v-btn>
                            &nbsp;&nbsp;
                            <v-btn :color="opcion_modal=='editar'?'blue':(opcion_modal=='aumentar'?'success':'info')" @click.native="editar(opcion_modal)">
                                <v-icon>done</v-icon> {{opcion_modal=='editar'?'Aceptar':(opcion_modal=='aumentar'?'Aumentar':'Disminuir')}}
                            </v-btn>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

        </v-card>
    </v-dialog>   
</div>
</template>

<script>
import Vue from "vue";

import moment from 'moment';
import {
    mapState,
    mapMutations
}

from "vuex";
export default {


    mounted: function () {

    },
    created: function () {
        this.sucursal_activa = this.$local_storage.get("sb_sucursal","0");
        if(this.sucursal_activa == "0"){ //Si en otra pestaña cambiaron a sucursal TODAS recargamos la pagina
            this.$router.go();            
        }
        this.getArticulos();
        this.cargar();
    },

    data() {
        return {
            opcion_modal: "editar",
            loadingArticulos: false,
            sucursal_activa:"",
            cantidad_editable: 0,
            codigo_editar: "",
            id_articulo:"",
            nombre_articulo_editar:"",
            id_articulo_editar:"",
            model_filters: "",
            props_paginacion: {
                infiniteHandler: this.infiniteHandler,
                total_registros: 0
            },
            modalEditar: false,            
            codigo_articulo: "",
            active_tab: "datos",
            menu1: false,
            fecha_parsed: "",
            marcas: [],
            productos: [],
            unidades: [],
            articulos: [],
            impuestos: [],
            categorias: [],
            tipos_precios: [],
            fab: [],
            name: "datagrid",
            fechaUsuario: "",
            columns: [
                "codigo_articulo",
                "nombre_articulo",
                "descripcion",
                "fecha",
                "existencia",
                "cantidad",
                "actions"
            ],
            show_loading: true,
            options: {
                perPage:10,
                pagination: {
                    show: true
                },
                filterable: ["codigo_articulo",
                    "nombre_articulo",
                    "fecha",
                ],
                sortable: ["codigo_articulo",
                    "nombre_articulo",
                    "fecha",
                ],
                orderBy: {
                    column: 'fecha',
                    ascending: false
                },
                headings: {
                    "codigo_articulo": "Codigo",
                    "nombre_articulo": "Nombre",
                    "existencia":"Existencia Actual",
                    "fecha": "Fecha",
                    "cantidad": "Conteo",
                    actions: "Operaciones"
                },
            },
            breadcrumbs: [{
                    text: "Inicio",
                    disabled: false,
                    to: "/index"
                },
                {
                    text: "Inventario",
                    disabled: true,
                    href: ""
                },
                {
                    text: "Conteo",
                    to: "/inventario/conteo"
                },
                {
                    text: "Actualización",
                    href: ""
                }
            ],

            modal: false,

            update: false,

            model: {
                _id: "",
                _rev: "",
                type: "lotes_conteo",
                id_sucursal: "",
                nombre_sucursal: "",
                usuario: "",
                fecha: "",
                descripcion:"",
                estatus: "Activo",
                articulos: [],
            },
            rules: {
                required: v => !!v || "Este campo es requerido",
            },
            search: "",
            registros: {
                selected: [],
                items: []
            },

        };
    },

    methods: {
        getArticulos:function(){
            this.loadingArticulos = true;
            let data = {
                "selector": {
                    "type": "articulos",
                    "estatus":"Activo",
                    //"proveedores.0": { "$exists": true }
                },
                "fields":["_id","nombre","codigo_barras"],
                "use_index":"_design/268be5c91c6ac7ca4ba302055e92efd1ee038633"
            };
            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                .then(response => {
                    response.data.docs.sort(this.ordenar_nombre);
                    this.articulos = response.data.docs;
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });
                }).then(() => {
                    this.loadingArticulos = false;
                });
        },
        upperCaseInput(fieldName) {
            // Guarda la posición actual del cursor
            const input = event.target;
            const start = input.selectionStart;
            const end = input.selectionEnd;

            // Transforma el valor del campo a mayúsculas
            const fieldValue = this[fieldName];
            const upperCaseValue = fieldValue.toUpperCase();

            // Actualiza el valor del campo en el modelo de datos
            this[fieldName] = upperCaseValue;

            // Actualiza el valor del input en la vista
            input.value = upperCaseValue;

            // Restablece la posición del cursor
            input.setSelectionRange(start, end);
        },
        editar: function (opcion="editar") {
            if (this.$refs.form.validate()) {
                    
                this.codigo_articulo = this.codigo_editar;
                this.buscarArticulo(parseFloat(this.cantidad_editable), "", opcion);//Para que lo ingrese
                
                /*this.modalEditar = false;
                this.codigo_articulo = "";
                this.$nextTick(() => this.$refs.ingresaarticulo.focus());*/
            }
        },
        
        mostrarEditar: function (row, opc_modal="editar") {
            this.nombre_articulo_editar = row.nombre_articulo;
            this.id_articulo_editar = row.id_articulo;
            this.codigo_editar = row.codigo_articulo[0];
            if(opc_modal == "editar")
                this.cantidad_editable = row.cantidad;
            else 
                this.cantidad_editable = 1;
            
            this.opcion_modal = opc_modal;
            this.modalEditar = true;
            
            setTimeout(() => {
                this.$nextTick(() => this.$refs.ingresacantidad.focus());
            }, 100);

        },
        validaCantidadFloat: function (value) {
            const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
            if (value != null && !pattern.test(value)) {
                return "Cantidad no válida"
            } else if (value != null && value != "") {
                if (parseFloat(value) < 0){
                    return "No debe ser negativo";
                } else if(parseFloat(value) > 99999){
                    return "Cantidad muy grande";
                }
            } 
            return true;

        },
        cargar: function () {
            var rows_per_page = 1;
            let self = this;
            let where = {"_id":self.$local_storage.get("sb_lote_conteo")};

            axios({
                    method: 'GET',
                    url: process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/'+self.$local_storage.get("sb_lote_conteo"),
                    withCredentials: true,
                }).then(response => {
                    if (response.data) {
                        this.registros.items = response.data.articulos;
                        this.props_paginacion.total_registros = this.registros.items.length;
                        this.model = response.data;
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },

        buscarArticulo: function (cantidad_parametro="", codigo_barras="", opcion="editar", llamada="") {
            let self = this;
            if(codigo_barras != "" && codigo_barras.length>0){
                this.codigo_articulo = codigo_barras[0];
            }
            if (this.codigo_articulo != null && this.codigo_articulo.toString().trim() != "") {

                let where = {"type":"articulos"};

                where["codigo_barras"] = {
                    "$in": [self.codigo_articulo]
                }

                var busca = encodeURI(escape(self.codigo_articulo));
                    window.axios
                    .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/busca_codigo?key=\"'+busca+'\"')
                    .then(async responseArticulo => {

                        if(responseArticulo.data != null && responseArticulo.data.rows!=null && responseArticulo.data.rows.length>0){
                            var articulo = responseArticulo.data.rows[0].value;

                            //Obtenemos el conteo actualizado
                            window.axios
                            .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model._id)
                            .then(response => {
                                this.registros.items = response.data.articulos;
                                this.props_paginacion.total_registros = this.registros.items.length;
                                this.model = response.data;                            

                                var filter = this.registros.items.find(function(e){return articulo._id == e.id_articulo });
                                if (filter) {
                                    var existencia = 0;
                                    window.funciones_lotes.consultaExistencia({
                                        "id_articulo": articulo._id,
                                        "id_sucursal": this.sucursal_activa
                                    }).then(result => {
                                        if (result.length > 0) {

                                            result.forEach(el => {
                                                existencia += el.existencia
                                            });
                                        }
                                        if(cantidad_parametro !== "" && cantidad_parametro >= 0){ 
                                            if(opcion == "editar") 
                                                filter.cantidad = cantidad_parametro;
                                            else if(opcion == "aumentar")   
                                                filter.cantidad += cantidad_parametro;
                                            else if(opcion == "disminuir")
                                                filter.cantidad -= cantidad_parametro;
                                        } else{                                                                                
                                            filter.cantidad = filter.cantidad + 1;
                                        }
                                        filter.existencia = existencia;
                                        filter.fecha = window.moment().format("YYYY-MM-DDTHH:mm:ss");
                                        filter.diferencia = filter.cantidad - filter.existencia;
                                        filter.diferencia_precio_compra = (filter.cantidad * filter.precio_compra) - (filter.existencia * filter.precio_compra);
                                        filter.diferencia_precio_compra_con_iva = (filter.cantidad * filter.precio_compra_con_iva) - (filter.existencia * filter.precio_compra_con_iva);
                                        
                                        let data = this.model;
                                        data.articulos = this.registros.items;

                                        window.dialogLoader.show('Espere un momento por favor..');
                                        window.axios
                                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
                                            .then(response => {

                                                if (response.data.ok) {
                                                    self.model._rev = response.data.rev;
                                                }
                                                window.dialogLoader.hide();
                                                this.modalEditar = false;
                                                this.codigo_articulo = "";
                                                this.id_articulo = "";
                                                if(llamada=="inicio"){//Solo cuando se manda llamar desde inicio se abre ventana
                                                    //(29-09-2023): Alvaro y Martín solicitaron que al agregar un producto se agrege en cero y muestre la ventana de Aumentar
                                                    this.$nextTick(() => this.mostrarEditar(filter,'aumentar') );
                                                } else {
                                                    this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                                                }
                                                
                                                

                                            })
                                            .catch(error => {
                                                if (error.response && error.response.status == 409) { //409 ocurrió un conflicto al guardar
                                                    //se manda la cantidad_original porque hubo conflicto y al traerse el registro actual, solo se aumenta/disminuye la cantidad original
                                                    this.buscarArticulo(cantidad_parametro, '', opcion, llamada);//Para que lo ingrese
                                                } else {
                                                    window.dialogLoader.hide();
                                                    window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..' + error, {
                                                        color: 'error'
                                                    });
                                                }                            
                                            });
                                        //self.updateRegistro(0, filter.codigo_articulo[0], filter.cantidad, opcion);

                                    }).catch(err => {
                                        this.buscarArticulo(cantidad_parametro, '', opcion, llamada);//Para que lo ingrese
                                        console.log("error", err);
                                    });
                                    
                                } else{
                                    var existencia = 0;
                                    window.funciones_lotes.consultaExistencia({
                                        "id_articulo": articulo._id,
                                        "id_sucursal": this.sucursal_activa
                                    }).then(result => {
                                        if (result.length > 0) {

                                            result.forEach(el => {
                                                existencia += el.existencia
                                            });
                                        }
                                        //console.log("se asigno la existencia");
                                        var cantidad_param = 0;
                                        if(cantidad_parametro !== "" && cantidad_parametro >= 0){
                                            cantidad_param = cantidad_parametro;
                                        } else{
                                            cantidad_param = 1;
                                        }
                                        var row = {
                                            "id_articulo": articulo._id,
                                            "codigo_articulo": articulo.codigo_barras,
                                            "nombre_articulo": articulo.nombre,
                                            "descripcion": articulo.descripcion,
                                            "fecha": window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                                            "cantidad": cantidad_param,
                                            "existencia": existencia,
                                            "precio_compra": articulo.precio_compra,
                                            "precio_compra_con_iva": articulo.precio_compra_con_iva,
                                            "diferencia": cantidad_param - existencia,
                                            "diferencia_precio_compra": (cantidad_param * articulo.precio_compra) - (existencia * articulo.precio_compra),
                                            "diferencia_precio_compra_con_iva": (cantidad_param * articulo.precio_compra_con_iva) - (existencia * articulo.precio_compra_con_iva),
                                        };
                                        this.registros.items.push(row);

                                        let data = this.model;
                                        data.articulos = this.registros.items;

                                        window.dialogLoader.show('Espere un momento por favor..');
                                        window.axios
                                            .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
                                            .then(response => {

                                                if (response.data.ok) {
                                                    self.model._rev = response.data.rev;
                                                }
                                                window.dialogLoader.hide();
                                                this.modalEditar = false;
                                                this.codigo_articulo = "";
                                                this.id_articulo = "";
                                                if(llamada=="inicio"){//Solo cuando se manda llamar desde inicio se abre ventana
                                                    //(29-09-2023): Alvaro y Martín solicitaron que al agregar un producto se agrege en cero y muestre la ventana de Aumentar
                                                    this.$nextTick(() => this.mostrarEditar(row,'aumentar') );
                                                } else {
                                                    this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                                                }

                                            })
                                            .catch(error => {
                                                if (error.response && error.response.status == 409) { //409 ocurrió un conflicto al guardar
                                                    //se manda la cantidad_original porque hubo conflicto y al traerse el registro actual, solo se aumenta/disminuye la cantidad original
                                                    this.buscarArticulo(cantidad_parametro, '', opcion, llamada);//Para que lo intente de nuevo
                                                } else {
                                                    window.dialogLoader.hide();
                                                    window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..' + error, {
                                                        color: 'error'
                                                    });
                                                }                            
                                            });

                                        //self.updateRegistro(0, articulo.codigo_barras[0], cantidad_param, opcion);

                                    }).catch(err => {
                                        console.log("error", err);
                                        this.buscarArticulo(cantidad_parametro, '', opcion, llamada);//Para que lo intente de nuevo
                                    });

                                }
                                
                            })
                            .catch(error => {
                                console.log("Error al obtener nuevamente el registro: ",error);                            
                            });   

                            

                        } else {
                            window.dialogLoader.showSnackbar('Articulo no encontrado', {
                                color: 'error'
                            });
                        }

                    }).catch(error => {
                        window.dialogLoader.hide(); 
                        self.$swal({
                            type: "error",
                            title: "¡Operación no Permitida!",
                            text: "Ocurrió un error al buscar el artículo. Intente nuevamente.",
                            footer: ""
                        });
                    });
            } else {
                this.codigo_articulo = "";
                setTimeout(() => {
                    this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                }, 100);
            }

        },

        formatDate(date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        parseDate(date) {
            if (!date) return null

            const [month, day, year] = date.split('-')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        parseDateTime(date) {
            if (!date) return null

            return moment(String(date)).format('DD/MM/YYYY hh:mm');
        },
        validaFloat: function (campo) {
            const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
            let value = this.model[campo];
            if (!pattern.test(value)) {
                this.model[campo] = this.model[campo].substring(0, this.model[campo].length - 1);
                this.validaFloat(campo);
                //console.log("INVALIDO")
            }
            //else
            //console.log("VALIDO");

        },

        updateRegistro: function (reintentar=0, codigo_articulo="", cantidad_articulo="", opcion="editar", cantidad_original=0) {
              let self = this;

                if(reintentar == 1){//Si hay conflictos se obtiene la ultima revisión y se intenta actualizar
                    window.dialogLoader.show('Espere un momento por favor..');
                    window.axios
                        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model._id)
                        .then(response => {
                            this.registros.items = response.data.articulos;
                            this.props_paginacion.total_registros = this.registros.items.length;
                            this.model = response.data;                            

                            this.codigo_articulo = codigo_articulo;
                            //se manda la cantidad_original porque hubo conflicto y al traerse el registro actual, solo se aumenta/disminuye la cantidad original
                            this.buscarArticulo(cantidad_original, '', opcion);//Para que lo ingrese
                            
                        })
                        .catch(error => {
                            console.log("Error al obtener nuevamente el registro: ",error);                            
                        });   
                } else {
                    let data = this.model;
                    data.articulos = this.registros.items;

                    window.dialogLoader.show('Espere un momento por favor..');
                    window.axios
                        .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
                        .then(response => {

                        if (response.data.ok) {
                            self.model._rev = response.data.rev;
                        }

                        })
                        .catch(error => {
                            if (error.response && error.response.status == 409) { //409 ocurrió un conflicto al guardar
                                //se manda la cantidad_original porque hubo conflicto y al traerse el registro actual, solo se aumenta/disminuye la cantidad original
                                this.buscarArticulo(cantidad_original, '', opcion);//Para que lo ingrese
                            } else {
                                window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..' + error, {
                                    color: 'error'
                                });
                            }                            
                        }).then(() => {
                            this.modal = false;
                            window.dialogLoader.hide();
                        });
                }                  

        },
        delete_articulo: function(row){
            this.$swal({
                type: "info",
                title: "Cuidado",
                text: "¿Desea remover el artículo: "+row.nombre_articulo+"?",
                footer: "",
                showCancelButton: true,
                cancelButtonColor: "#d33",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Si",
                cancelButtonText: "No"
            }).then(resultado => {
                if (resultado.value) {
                    window.dialogLoader.show('Espere un momento por favor..');
                    window.axios
                        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model._id)
                        .then(response => {
                            this.registros.items = response.data.articulos;
                            this.props_paginacion.total_registros = this.registros.items.length;
                            this.model = response.data;  

                            var articulos_filter = this.model.articulos.filter(e => e.id_articulo != row.id_articulo);
                            let data = this.model;
                            data.articulos = articulos_filter;
                            
                            window.dialogLoader.show('Espere un momento por favor..');
                            window.axios
                                .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
                                .then(response => {
                                    if (response.data.ok) {
                                        this.model._rev = response.data.rev;
                                        this.registros.items = articulos_filter;
                                        this.$nextTick(() => this.$refs.ingresaarticulo.focus());
                                    }
                                }).catch(error => {
                                    this.delete_articulo(row);
                                }).then(() => {                            
                                    window.dialogLoader.hide();
                                });
                            
                        })
                        .catch(error => {
                            console.log("Error al obtener nuevamente el registro: ",error);                            
                            window.dialogLoader.hide();
                            this.delete_articulo(row);
                        });  
                }
            });
            
        },

    }
};
</script>

<style scoped>
.footer-hide>>>.VuePagination {
    display: none;
}

.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active)>.v-icon,
.theme--light.v-tabs>.v-tabs-bar .v-tab--disabled {
    color: black;
    font-size: small;
    background-color: #EEEEEE;
    padding: 0px;
}

.v-tabs .v-tabs-bar .v-tab.v-tab--active {
    color: white;
    font-size: small;
    background-color: #df7205;
    padding: 0px;
    font-weight: bold;

}

.v-tabs-slider-wrapper {
    left: 0 !important;
}

.table-bordered thead th,
.table-bordered thead td {
    font-size: x-small !important;
}
</style>
